import React from "react";
import logo from "../../Assets/Logos/logowhite.png";
import "./Header.scss";
import { NavLink } from "react-router-dom";

export default class Header extends React.Component {
  render() {
    return (
      <div className="header d-flex flex-column  flex-sm-row justify-content-between my-5 px-5">
        <div className="py-1 d-flex align-items-center">
          <div>
            <img src={logo} alt="logo" id="header-logo" className="m-3" />
          </div>
          <div>
            <h2 className="text-white text-start fw-bold">SIDE-EYE</h2>
            <h3 className="text-white text-start fw-light">
              VFX and Animation Studio
            </h3>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <NavLink to="/" className="text-white px-1">
            Home
          </NavLink>
          <NavLink to="/contact" className="text-white px-1">
            Contact
          </NavLink>
        </div>
      </div>
    );
  }
}
